
import { defineComponent, ref, watch } from "vue";
import commonIonicComponents from "../shared/common-ionic-components";
import { videocam, peopleCircle, close, share, cog } from "ionicons/icons";

import { useRoute } from "vue-router";
import { useToggleStore } from "@/stores/toggle";
export default defineComponent({
  name: "sidebarComponent",

  components: {
    ...commonIonicComponents,
  },

  setup() {
    const route = useRoute();
    const toggle = useToggleStore();
    const activeRoute = ref(route.path);
    watch(route, (oldValue, newValue) => {
      activeRoute.value = newValue.path;
    });

    const closeMenu = () => {
      toggle.toggleMenu();
    };
    return {
      videocam,
      peopleCircle,
      close,
      closeMenu,
      share,
      cog,
      activeRoute,
    };
  },
});

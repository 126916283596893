import {
  IonToolbar,
  IonGrid,
  IonCol,
  IonRow,
  IonTitle,
  IonHeader,
  IonContent,
  IonCard,
  IonSearchbar,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonCheckbox,
  IonChip,
  IonIcon,
  IonSpinner,
  popoverController,
  modalController,
  IonButton,
  IonModal,
  IonInput,
  IonCardTitle,
  IonCardSubtitle,
  IonPage,
  IonButtons,
  IonPopover,
  IonCardContent,
  IonAvatar,
  IonThumbnail,
  IonDatetime,
  IonTextarea,
  IonCardHeader,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";

export default {
  IonToolbar,
  IonGrid,
  IonCol,
  IonList,
  IonListHeader,
  IonCheckbox,
  IonSpinner,
  IonButtons,
  IonTextarea,
  IonRow,
  IonModal,
  IonDatetime,
  popoverController,
  modalController,
  IonChip,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonAvatar,
  IonItem,
  IonIcon,
  IonButton,
  IonSearchbar,
  IonHeader,
  IonLabel,
  IonInput,
  IonContent,
  IonThumbnail,
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonPage,
  IonCardContent,
  IonCardHeader,
};

import { GameActions } from "./../../types/Gameactions";
import { User } from "./../../types/User";
import { ref } from "vue";
import { supabase } from "./supabaseConfig";
import { VideoShare } from "@/types/VideoShare";
const userSession = ref();
export const getSession = async () => {
  userSession.value = supabase.auth.session();
  supabase.auth.onAuthStateChange((event: any, session: any) => {
    userSession.value = session;
  });
  return userSession.value;
};

export const signUpWithEmail = async (
  email: string,
  password: string,
  userData?: any
) => {
  const { user, error } = await supabase.auth.signUp(
    {
      email,
      password,
    },
    { data: userData }
  );
  return { user, error };
};

export const signInWithEmail = async (email: string, password: string) => {
  const { user, error } = await supabase.auth.signIn({
    email,
    password,
  });
  return { user, error };
};

export const getCurrentUser = async () => {
  const { user, error } = await supabase.auth.api.getUser(
    userSession.value.access_token
  );
  return { user, error };
};

export const forgetPassword = async (email: string) => {
  const result = await supabase.auth.api.resetPasswordForEmail(email);
  return result;
};

export const logUserOut = async () => {
  try {
    await supabase.auth.signOut();
  } catch (error) {
    //
  }
};

export const updatePassword = async (new_password: string) => {
  const { data, error } = await supabase.auth.api.updateUser(
    userSession.value.access_token,
    {
      password: new_password,
    }
  );
  return { data, error };
};

export const saveUserToDatabase = async (user: User): Promise<any> => {
  const { data, error } = await supabase.from("users").insert([user]);
  return { data, error };
};

export const getUserVideo = async (id: string) => {
  const { data, error } = await supabase
    .from("video")
    .select("*")
    .match({ user_uid: id, status: "active" })
    .order("updated_at", { ascending: false });

  return { data, error };
};

export const addPlayerToDatabase = async (player: any) => {
  const { data, error } = await supabase.from("player").insert([player]);

  return { data, error };
};

export const deletePlayer = async (id: any) => {
  const { data, error } = await supabase.from("player").delete().eq("id", id);
  return { data, error };
};

export const editPlayer = async (player: any) => {
  const { data, error } = await supabase
    .from("player")
    .update({
      first_name: player.first_name,
      last_name: player.last_name,
      shirt_number: player.shirt_number,
      updated_at: new Date(),
    })
    .eq("id", player.id);
  return { data, error };
};

export const getTeamId = async () => {
  const { data }: any = await supabase
    .from("team")
    .select("id")
    .eq("user_uid", userSession.value.user.id);
  return data;
};

export const getUserPlayers = async () => {
  const teamId: any = await getTeamId();
  const { data: result, error } = await supabase
    .from("player")
    .select("*")
    .eq("team_id", teamId[0].id);

  return { result, error };
};

export const getSports = async () => {
  const { data, error } = await supabase
    .from("sport")
    .select("*")
    .eq("status", "active");

  return { data, error };
};

export const saveGameAction = async (gameAction: GameActions) => {
  const teamId: any = await getTeamId();
  gameAction.team_id = teamId[0].id;
  delete gameAction.sn;
  const { data, error } = await supabase
    .from("game_action")
    .insert([gameAction]);
  await supabase
    .from("video")
    .update({
      updated_at: new Date(),
    })
    .eq("id", gameAction.video_id);
  return { data, error };
};

export const deleteGameAction = async (gameAction: GameActions) => {
  const { data, error } = await supabase
    .from("game_action")
    .delete()
    .eq("id", gameAction.id);
  await supabase
    .from("video")
    .update({
      updated_at: new Date(),
    })
    .eq("id", gameAction.video_id);
  return { data, error };
};

export const checkIfVideoExist = async (videoId: any) => {
  const { data } = await supabase
    .from("video")
    .select("*")
    .eq("external_id", videoId);
  if (data && data.length > 0) {
    return true;
  } else if (data && data.length < 1) {
    return false;
  }
  // return { data, error };
};

export const deleteVideoFromDatabase = async (videoId: any) => {
  const { data, error } = await supabase
    .from("video")
    .update({
      status: "deleted",
      deleted_at: new Date().toJSON(),
    })
    .eq("id", videoId);
  return { data, error };
};

export const getSportName = async (sportId: any) => {
  const { data, error } = await supabase
    .from("sport")
    .select("name")
    .eq("id", sportId);
  return { data, error };
};

export const getGameActions = async (id: any) => {
  const { data, error } = await supabase
    .from("game_action")
    .select("*")
    .order("created_at", { ascending: false })
    .eq("video_id", id);

  return { data, error };
};

export const getAllGameActions = async () => {
  const { data, error } = await supabase
    .from("game_action")
    .select("*")
    .order("created_at", { ascending: false });

  return { data, error };
};

export const getGameActionTypes = async (id: any) => {
  const { data, error } = await supabase
    .from("game_action_type")
    .select("*")
    .eq("sports_id", id);

  return { data, error };
};

export const addVideoToCollection = async (videoData: any) => {
  videoData.user_uid = userSession.value.user.id;

  const { data, error } = await supabase.from("video").insert([videoData]);
  return { data, error };
};

export const updateTrackingSettings = async (id: any, settings: any) => {
  const { data, error } = await supabase
    .from("video")
    .update({
      tracking_settings: settings,
      updated_at: new Date(),
    })
    .eq("id", id);
  return { data, error };
};

export const createLink = async (videoShareData: VideoShare) => {
  videoShareData.user_uid = userSession.value.user.id;
  const { data, error } = await supabase
    .from("videoShare")
    .insert([videoShareData]);

  await supabase
    .from("video")
    .update({
      updated_at: new Date(),
    })
    .eq("id", videoShareData.video_id);

  return { data, error };
};

export const getSharedVideoLink = async (linkId: any) => {
  const { data, error } = await supabase
    .from("videoShare")
    .select("*")
    .eq("link_id", linkId);
  return { data, error };
};

export const deleteShareLink = async (videoShareData: any) => {
  const { data, error } = await supabase
    .from("videoShare")
    .delete()
    .eq("link_id", videoShareData.link_id);

  await supabase
    .from("video")
    .update({
      updated_at: new Date(),
    })
    .eq("id", videoShareData.video_id.id);

  return { data, error };
};

export const editShareLink = async (videoShareData: any) => {
  const { link_description, link_id, link_title } = videoShareData;
  const updated_at = new Date(Date.now()).toISOString();
  const { data, error } = await supabase
    .from("videoShare")
    .update({ link_description, link_id, link_title, updated_at })
    .eq("id", videoShareData.id);

  await supabase
    .from("video")
    .update({
      updated_at: new Date(),
    })
    .eq("id", videoShareData.video_id.id);

  return { data, error };
};

export const getUserSharedVideoLinks = async () => {
  const { data, error } = await supabase
    .from("videoShare")
    .select(
      `* , video_id(
      title, id, image_url
  )`
    )
    .eq("user_uid", userSession.value.user.id);
  return { data, error };
};

export const getVideoSharedLink = async (videoId: any) => {
  const { data, error } = await supabase
    .from("videoShare")
    .select(
      `* , video_id(
      title, id, image_url
  )`
    )
    .eq("video_id", videoId);
  return { data, error };
};

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "left",
  id: "left"
}
const _hoisted_2 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_sidebar_component = _resolveComponent("sidebar-component")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      (_ctx.route.meta.noSidebar)
        ? (_openBlock(), _createBlock(_component_ion_router_outlet, { key: 0 }))
        : (_openBlock(), _createBlock(_component_ion_row, {
            key: 1,
            class: "content"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_sidebar_component)
              ]),
              _createElementVNode("div", _hoisted_2, [
                (!_ctx.authenticated)
                  ? (_openBlock(), _createBlock(_component_ion_spinner, {
                      key: 0,
                      name: "dots",
                      class: "main"
                    }))
                  : (_openBlock(), _createBlock(_component_ion_router_outlet, { key: 1 }))
              ])
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}
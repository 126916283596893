import { GameActions } from "./../types/Gameactions";
import { defineStore } from "pinia";

export const useGameActionStore = defineStore({
  id: "gameActionStore",

  state: () => ({
    allGameActions: [] as GameActions[],
  }),

  actions: {
    setAllGameActions(gameAtions: GameActions[]) {
      this.allGameActions = gameAtions;
    },

    setGameAction(gameAction: GameActions) {
      const lastIndex: any = this.allGameActions[0].id; //this.allGameActions[0].id + 1;
      gameAction.id = lastIndex + 1;
      this.allGameActions.unshift(gameAction);
    },

    deleteGameAction(gameAction: GameActions) {
      const filtered = this.allGameActions.filter(
        (x: GameActions) => x.id !== gameAction.id
      );
      this.allGameActions = filtered;
    },
  },

  getters: {
    getAllGameActions: (state: any) => state.allGameActions,

    getVideoGameActionsCount: (state: any) => {
      return (id: any) => {
        const filtered: any = state.allGameActions.filter(
          (x: GameActions) => x.video_id == id
        );
        return filtered.length;
      };
    },

    getPlayerGameActionsCount: (state: any) => {
      return (id: any) => {
        const filtered: any = state.allGameActions.filter(
          (x: GameActions) => x.player_id == id
        );
        return filtered.length;
      };
    },
    getGameActions: (state: any) => {
      return (id: any) => {
        const filtered: any = state.allGameActions.filter(
          (x: GameActions) => x.video_id == id
        );
        return filtered;
      };
    },
  },
});

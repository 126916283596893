import { VideoShare } from "@/types/VideoShare";

import { defineStore } from "pinia";
export const useSharedLinksStore = defineStore({
  id: "sharedLinksStore",

  state: () => ({
    sharedLinks: [] as VideoShare[],
  }),

  actions: {
    setSharedLinks(links: VideoShare[]) {
      this.sharedLinks = links;
    },
    updateSharedLink(link: VideoShare) {
      const filtered: any = this.sharedLinks.filter(
        (x: VideoShare) => x.id !== link.id
      );
      filtered.push(link);
      this.sharedLinks = filtered;
    },
    setSharedLink(link: VideoShare) {
      this.sharedLinks.push(link);
    },

    deleteSharedLink(linkId: any) {
      this.sharedLinks = this.sharedLinks.filter(
        (x: VideoShare) => x.link_id !== linkId
      );
    },
  },

  getters: {
    getSharedLinks: (state) => state.sharedLinks,

    getsharedLink: (state) => {
      return (id: any) => {
        const filtered: any = state.sharedLinks.filter(
          (x: VideoShare) => x.id == id
        );
        return filtered[0];
      };
    },

    getVideosharedLinks: (state) => {
      return (videoId: any) => {
        const filtered: any = state.sharedLinks.filter(
          (x: any) => x.video_id.id == videoId || x.video_id == videoId
        );
        return filtered;
      };
    },

    getVideosharedLinksCount: (state) => {
      return (videoId: any) => {
        const filtered: any = state.sharedLinks.filter(
          (x: any) => x.video_id.id == videoId
        );
        return filtered.length;
      };
    },
  },
});

import { isLoggedIn } from "@/services/auth/auth.service";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import TabsPage from "../views/TabsPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/authPage",
  },

  {
    path: "/authPage",
    component: () => import("../views/authentication/AuthPage.vue"),
    meta: { requiresAuth: false, noSidebar: true },
  },
  {
    path: "/tracking/:id",
    component: () => import("../views/TrackingPage.vue"),
    meta: { requiresAuth: true, noSidebar: true },
  },
  {
    path: "/videoplayer/:id/:videoId",
    component: () => import("../views/VideoPlayerPage.vue"),
    meta: { requiresAuth: true, noSidebar: true },
  },

  {
    path: "/view/:uuid",
    component: () => import("../views/ViewerPage.vue"),
    meta: { requiresAuth: false, noSidebar: true },
  },
  {
    path: "/shared-links/:videoId",
    component: () => import("../views/VideoSharedPage.vue"),
    meta: { requiresAuth: true, noSidebar: false },
  },
  {
    path: "/authPage/:id",
    component: () => import("../views/authentication/AuthPage.vue"),
    meta: { requiresAuth: false, noSidebar: true },
  },

  {
    path: "/tabs/",
    component: TabsPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        redirect: "/tabs/home",
      },
      {
        path: "home",
        component: () => import("@/views/tabs/HomePage.vue"),
      },
      {
        path: "players",
        component: () => import("@/views/tabs/PlayersPage.vue"),
      },
      {
        path: "shared",
        component: () => import("@/views/tabs/SharedPage.vue"),
      },
      {
        path: "settings",
        component: () => import("@/views/tabs/SettingsPage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const loggedIn = await isLoggedIn();
  if (to.meta.requiresAuth && !loggedIn) {
    next("/authPage");
  } else if (to.fullPath.includes("type=recovery")) {
    next("/authPage/changePassword");
  } else if (to.fullPath.includes("type=signup")) {
    next("/authPage/login");
  } else if (to.fullPath == "/authPage" && loggedIn) {
    next("/tabs/home");
  } else {
    next();
  }
});

export default router;


import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import sidebarComponent from "./components/sidebarComponent.vue";
import commonIonicComponents from "@/shared/common-ionic-components";
import { useRoute } from "vue-router";

import { useVideoStore } from "@/stores/videos";
import { useGameActionStore } from "@/stores/gameActions";
import { useToggleStore } from "@/stores/toggle";
import {
  getSession,
  getAllGameActions,
  getUserVideo,
  getUserSharedVideoLinks,
} from "./services/supabase/supabaseClient";
import { useSharedLinksStore } from "./stores/sharedLinks";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    sidebarComponent,
    ...commonIonicComponents,
  },

  setup() {
    const route = useRoute();
    const toggle = useToggleStore();
    const videoStore = useVideoStore();
    const gameActionStore = useGameActionStore();
    const sharedLinkStore = useSharedLinksStore();
    const authenticated = ref(false);
    const ready = ref(false);
    const menuState = computed(() => {
      return toggle.menuState;
    });
    const init = async () => {
      const userSession = await getSession();
      const userId = userSession.user.id;
      const { data } = await getUserVideo(userId);
      const result = await getAllGameActions();
      const result2 = await getUserSharedVideoLinks();

      if (data && result.data && result2.data) {
        videoStore.setVideos(data);
        gameActionStore.setAllGameActions(result.data);
        sharedLinkStore.setSharedLinks(result2.data);
        ready.value = true;
        authenticated.value = true;
      } else {
        alert("Error loading page, Please reload");
      }
    };

    watch(menuState, (currentValue, oldValue) => {
      console.log(currentValue, oldValue);
      const el: any = document.getElementById("left");
      if (menuState.value) {
        el.style.transform = "translateX(0)";
        el.style.width = "100%";
      } else {
        el.style.transform = "translateX(-300px)";
        el.style.width = "fit-content";
      }
    });

    watch(route, () => {
      if (!route.meta.noSidebar && route.fullPath == "/tabs/home") {
        init();
      }
    });

    onMounted(() => {
      if (!route.meta.noSidebar) {
        init();
      }
    });

    return {
      route,
      authenticated,
      menuState,
      ready,
    };
  },
});

import { Videos } from "./../types/Videos";
import { defineStore } from "pinia";
export const useVideoStore = defineStore({
  id: "videoStore",

  state: () => ({
    videos: [] as Videos[],
  }),

  actions: {
    setVideos(videos: Videos[]) {
      this.videos = videos;
    },
    updateVideo(video: Videos) {
      const filtered: any = this.videos.filter(
        (x: Videos) => x.id !== video.id
      );
      filtered.push(video);
      this.videos = filtered;
    },
    setVideo(video: Videos) {
      this.videos.push(video);
    },
    deleteVideo(videoId: any) {
      this.videos.splice(
        this.videos.findIndex((x: Videos) => x.id === videoId),
        1
      );
    },
  },

  getters: {
    getVideos: (state) => state.videos,

    getVideo: (state) => {
      return (id: any) => {
        const filtered: any = state.videos.filter((x: Videos) => x.id == id);
        return filtered[0];
      };
    },
  },
});

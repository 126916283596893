import { defineStore } from "pinia";

export const useToggleStore = defineStore({
  id: "toggleStore",

  state: () => ({
    menuState: false,
  }),

  actions: {
    toggleMenu() {
      this.menuState = !this.menuState;
    },
  },
});
